<template>
  <draggable
    v-model="draggableList"
    :disabled="!allows_crud"
    tag="b-tbody"
    handle=".handle"
  >
    <template v-if="draggableList.length > 0">
      <b-tr v-for="evaluation in draggableList" :key="evaluation.id">
        <b-td
          class="text-center handle"
          :class="{ grabbable: allows_crud }"
          v-if="allows_crud"
        >
          <b-icon
            v-if="allows_crud && draggableList.length > 1"
            icon="arrows-expand"
            scale="1.2"
          ></b-icon>
        </b-td>
        <b-td class="text-center">
          <span
            v-if="
              evaluation.evaluation != null &&
              institution &&
              institution.kl_assessment &&
              user &&
              ((!user.groups.includes(6) && !user.groups.includes(7)) ||
                user.is_superuser)
            "
            class="div-href"
            @click="redirectToEvaluation(evaluation.evaluation)"
          >
            {{ evaluation.name }}
          </span>
          <span v-else>
            {{ evaluation.name }}
          </span>
        </b-td>
        <b-td>
          <div
            class="rich-text-content px-2 description-evaluation"
            v-html="evaluation.description"
          ></div>
        </b-td>
        <b-td class="text-center">
          {{
            epm_evaluation_types.find((x) => x.id == evaluation.type)
              ? epm_evaluation_types.find((x) => x.id == evaluation.type).value
              : "N/A"
          }}</b-td
        >
        <b-td class="text-center">
          <span v-if="evaluation.type == 5">-</span>
          <span v-else-if="evaluation.type == 4">-</span>
          <span v-else> {{ evaluation.weighing }}% </span>
        </b-td>
        <b-td
          v-if="institution && institution.show_evaluation_criteria_macro"
          :class="
            evaluation.evaluation_criterias_macro.length > 0
              ? 'text-justify'
              : 'text-center'
          "
        >
          <template v-if="evaluation.evaluation_criterias_macro.length > 0">
            <template
              v-for="evaluation_criteria in evaluationCriteriasMacrosFilter"
            >
              <span
                :key="evaluation_criteria.id"
                v-if="
                  evaluation.evaluation_criterias_macro.includes(
                    evaluation_criteria.id
                  )
                "
              >
                <span style="position: absolute">
                  {{ evaluation_criteria.order }}
                </span>
                <SentenceContainer
                  style="padding-left: 5px; padding-top: 2px"
                  :Sentence="evaluation_criteria"
                  :boundedTextContainer="false"
                ></SentenceContainer>
              </span>
            </template>
          </template>
          <template v-else> N/A </template>
        </b-td>
        <b-td>
          <div
            v-for="(study_environment, index) in getMatterTimeAllocation(
              evaluation.id,
              true
            )"
            :key="'evaluation' + evaluation.id + 'index' + index"
          >
            <div>
              <strong>{{ study_environment.name }}</strong>
            </div>
            <div
              v-for="(time_allocation, index2) in study_environment.time_hours"
              :key="
                'evaluation' +
                evaluation.id +
                'index' +
                index +
                'index2' +
                index2
              "
            >
              <div class="ml-3">
                {{ time_allocation.name }}
                <b>{{ time_allocation.hours }} Hrs</b>
              </div>
            </div>
          </div>
        </b-td>
        <b-td>
          <div
            v-for="(study_environment, index) in getMatterTimeAllocation(
              evaluation.id,
              false
            )"
            :key="'evaluation' + evaluation.id + 'index' + index"
          >
            <div>
              <strong>{{ study_environment.name }}</strong>
            </div>
            <div
              v-for="(time_allocation, index2) in study_environment.time_hours"
              :key="
                '-evaluation' +
                evaluation.id +
                '-index' +
                index +
                '-index2' +
                index2
              "
            >
              <div class="ml-3">
                {{ time_allocation.name }}
                <b>{{ time_allocation.hours }} Hrs</b>
              </div>
            </div>
          </div>
        </b-td>
        <b-td
          :class="
            evaluationCriteriasMicros.filter((x) =>
              evaluation.evaluation_criterias_micro.includes(x.id)
            ).length > 0
              ? 'text-justify'
              : 'text-center'
          "
        >
          <template
            v-if="
              evaluationCriteriasMicros.filter((x) =>
                evaluation.evaluation_criterias_micro.includes(x.id)
              ).length > 0
            "
          >
            <template v-for="study_unit in filterECMicro">
              <div
                :key="study_unit.id"
                :class="{
                  'evaluation-criteria-container':
                    institution && institution.internal_use_id == 'duoc_uc',
                }"
              >
                <strong
                  class="mr-2"
                  :key="study_unit.id"
                  v-if="
                    study_unit.options.filter((x) =>
                      evaluation.evaluation_criterias_micro.includes(x.id)
                    ).length > 0
                  "
                  >{{ study_unit.name }}
                </strong>
                <div
                  class="ec-badge-container"
                  :key="'ec-container' + study_unit.id"
                >
                  <div
                    v-for="evaluation_criteria in study_unit.options"
                    :key="study_unit.id + ':' + evaluation_criteria.id"
                  >
                    <div
                      v-if="
                        evaluation.evaluation_criterias_micro.includes(
                          evaluation_criteria.id
                        )
                      "
                      :key="
                        'div' + study_unit.id + ':' + evaluation_criteria.id
                      "
                      class="text-center"
                    >
                      <b-badge
                        :id="`ec-badge-${evaluation.id}-${study_unit.id}-${evaluation_criteria.id}`"
                        class="ec-micro-badge"
                        v-b-tooltip.v-secondary.noninteractive="
                          `${evaluation_criteria.full_sentence}`
                        "
                      >
                        {{
                          study_unit.competence_unit_order != 999
                            ? study_unit.competence_unit_order + "."
                            : ""
                        }}{{ study_unit.order }}.{{ evaluation_criteria.order }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else><span> N/A </span> </template>
        </b-td>
        <b-td class="td-btn-wrapper" v-if="allows_crud">
          <ButtonWrapper :item_id="evaluation.id">
            <template v-slot:btn-slot>
              <b-button
                v-if="allows_crud"
                size="sm"
                fill="white"
                variant="none"
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Editar Recursos Bibliográficos'
                "
                @click="
                  $bvModal.show(
                    `m-bibliographic-resource-modal-${evaluation.id}`
                  )
                "
              >
                <b-icon icon="book"></b-icon>
              </b-button>
              <button-edit
                v-if="allows_crud"
                fill="white"
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Editar Actividad/Evaluación'
                "
                @click="
                  $bvModal.show(`edit-matter-evaluation-modal-${evaluation.id}`)
                "
              ></button-edit>
              <button-delete
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Eliminar Actividad/Evaluación'
                "
                @click="deleteEvaluation(evaluation.id, evaluation.type)"
              ></button-delete> </template
          ></ButtonWrapper>
          <b-modal
            :id="`m-bibliographic-resource-modal-${evaluation.id}`"
            title="Editar Recursos Bibliográficos"
            size="xl"
            hide-footer
          >
            <MEvaluationBibliographicResource
              :m_evaluation_id="evaluation.id"
              :matter_id="matter_id"
              :allows_crud="allows_crud"
            ></MEvaluationBibliographicResource>
          </b-modal>
          <b-modal
            :id="`edit-matter-evaluation-modal-${evaluation.id}`"
            title="Editar Actividad/Evaluación"
            size="xl"
            hide-footer
          >
            <MatterProgramEvaluationForm
              :matter_id="matter_id"
              :Evaluation="evaluation"
              :EvaluationSituations="evaluation_situations"
              :evaluation_list="filterMatterEvaluations"
              :EvaluativeAgentTypes="evaluative_agent_types"
              :MatEvaStuEnvTimAll="mat_eva_stu_env_tim_all"
              @updated="updatedEvaluation"
              @created_support_resource="createdSupportResource"
            >
            </MatterProgramEvaluationForm>
          </b-modal>
        </b-td>
      </b-tr>
    </template>
    <b-tr v-else>
      <b-td class="text-center" colspan="8"
        >No hay actividades o evaluaciones para mostrar.</b-td
      >
    </b-tr>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatterProgramEvaluationDraggable",
  components: {
    draggable,
    MatterProgramEvaluationForm: () =>
      import("./MatterProgramEvaluationForm.vue"),
    MEvaluationBibliographicResource: () =>
      import("./MEvaluationBibliographicResource.vue"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
  },
  props: {
    allows_crud: {
      type: Boolean,
    },
    matter_id: {
      type: Number,
      required: true,
    },
    learning_experience: {
      type: [Number, null],
      default: null,
    },
    EvaluationSituations: {
      type: Array,
    },
    EvaluativeAgentTypes: {
      type: Array,
    },
    MatEvaStuEnvTimAll: {
      type: Array,
    },
    include_transversal_evaluation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      evaluation_situations: this.EvaluationSituations,
      evaluative_agent_types: this.EvaluativeAgentTypes,
      mat_eva_stu_env_tim_all: this.MatEvaStuEnvTimAll,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      time_allocations: names.TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      studyUnits: names.STUDY_UNITS,
      matterEvaluations: names.MATTER_EVALUATIONS,
      epm_evaluation_types: names.EPM_EVALUATION_TYPES,
      competence_units: names.COMPETENCE_UNITS,
    }),
    filterMatterEvaluations() {
      if (!this.matter_id) return [];
      return this.matterEvaluations
        .filter((x) => x.matter == this.matter_id)
        .sort((a, b) => a.order - b.order);
    },
    draggableList: {
      get() {
        let list = [];
        if (this.include_transversal_evaluation)
          list = this.filterMatterEvaluations.filter(
            (x) =>
              x.learning_experience == this.learning_experience && x.type == 5
          );
        else
          list = this.filterMatterEvaluations.filter(
            (x) =>
              x.learning_experience == this.learning_experience && x.type != 5
          );
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.patchEvaluationOrder(element);
            }
          }
          order += 1;
        });
      },
    },
    evaluationCriteriasMacrosFilter() {
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    studyUnitsList() {
      if (!this.matter_id) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnitsList.forEach((study_unit) => {
        const evaluation_criterias = this.evaluationCriteriasMicros.filter(
          (x) => x.study_unit == study_unit.id
        );
        const competence_unit = this.competence_units.find(
          (c) => c.id == study_unit.temp_competence_unit
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String(
              "N°" +
                (competence_unit ? competence_unit.order + "." : "") +
                study_unit.order +
                ": " +
                study_unit.name
            ),
            order: study_unit.order,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
            options: this.evaluationCriteriasMicros
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list.sort(function (a, b) {
        if (a.competence_unit_order > b.competence_unit_order) return 1;
        if (a.competence_unit_order < b.competence_unit_order) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
  },
  methods: {
    getMatterTimeAllocation(matter_evaluation_id, is_pedagogical_hours) {
      const time_allocation_ids_is_pedagogical = this.time_allocations
        .filter((x) => x.uses_module_minutes == is_pedagogical_hours)
        .map((x) => x.id);
      const mat_eva_stu_env_time_all = this.MatEvaStuEnvTimAll.filter(
        (x) =>
          x.matter_evaluation == matter_evaluation_id &&
          time_allocation_ids_is_pedagogical.includes(x.time_allocation) &&
          x.hours > 0
      );
      if (mat_eva_stu_env_time_all.length > 0) {
        let list = [];
        const study_environments_ids = [
          ...new Set(mat_eva_stu_env_time_all.map((x) => x.study_environment)),
        ];
        this.studyEnvironments
          .filter((x) => study_environments_ids.includes(x.id))
          .forEach((study_environment) => {
            list.push({
              name: study_environment.name,
              time_hours: mat_eva_stu_env_time_all
                .filter((x) => x.study_environment == study_environment.id)
                .map((x) => ({
                  name:
                    (
                      this.time_allocations.find(
                        (time) => time.id == x.time_allocation
                      ) || {}
                    ).name || "",
                  hours: x.hours,
                })),
            });
          });
        if (study_environments_ids.includes(null)) {
          list.push({
            name:
              "Sin " +
              this.$getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ) +
              ":",
            time_hours: mat_eva_stu_env_time_all
              .filter((x) => x.study_environment == null)
              .map((x) => ({
                name:
                  (
                    this.time_allocations.find(
                      (time) => time.id == x.time_allocation
                    ) || {}
                  ).name || "",
                hours: x.hours,
              })),
          });
        }
        return list;
      } else return [];
    },
    createdSupportResource(response) {
      this.$emit("created_support_resource", response);
    },
    redirectToEvaluation(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "NewEvaluationView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    createdNewEvaluation(response, tests_list) {
      this.$store
        .dispatch(names.POST_NEW_EVALUATION, {
          title: response.name,
          description: response.description,
          weighing: response.weighing,
          visible_by_evaluatees: false,
          parent_evaluation: null,
          files: [],
        })
        .then((new_evaluation) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_EVALUATION_BY_MATTER_EVALUATION",
            {
              new_evaluation_id: new_evaluation.id,
              matter_evaluation_id: response.id,
            }
          );
          this.$restful
            .Post("/evaluations2/evaluation-scope-default/", {
              scope: 3,
              scope_id: this.matter_id,
              evaluation: new_evaluation.id,
            })
            .then((evaluation_scope) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_SCOPE_BY_NEW_EVALUATION",
                evaluation_scope
              );
              response.evaluation = new_evaluation.id;
              this.$store
                .dispatch(names.PATCH_MATTER_EVALUATION, {
                  matter_evaluation_id: response.id,
                  item: { evaluation: new_evaluation.id },
                })
                .then(() => {
                  this.bulkCreateTest(tests_list, new_evaluation);
                });

              // evaluations2: sumativa 1, diagnóstica 2, formativa 3
              // evaluation_type:  diagnóstica 1, formativa 2, sumativa 3, actividad 4, transversal 5
              let evaluation_type = null;
              if (response.type == 1) evaluation_type = 2;
              else if ([2, 4].includes(response.type)) evaluation_type = 3;
              else if ([3, 5].includes(response.type)) evaluation_type = 1;
              else evaluation_type = 3;
              this.saveEvaluationTypeDefault(new_evaluation, {
                id: new_evaluation.evaluation_type_default,
                type: evaluation_type,
                evaluation: new_evaluation.id,
              });
              if (
                this.filterMatterEvaluations.find((x) => x.id == response.id)
              ) {
                this.$bvModal.hide(
                  `edit-matter-evaluation-modal-${response.id}`
                );
              }
            });
        });
    },
    saveEvaluationTypeDefault(new_evaluation, evaluation_type) {
      if (evaluation_type) {
        if (new_evaluation.evaluation_type_default == null) {
          this.$restful
            .Post("/evaluations2/evaluation-type-default/", evaluation_type)
            .then((response) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
                response
              );
            });
        } else {
          this.$restful
            .Put(
              `/evaluations2/evaluation-type-default/${evaluation_type.id}/`,
              evaluation_type
            )
            .then((response) => {
              this.$store.commit(
                "MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION",
                response
              );
            });
        }
      }
    },
    async bulkCreateTest(test_list, new_evaluation) {
      for await (let test of test_list) {
        if (isNaN(test.id)) {
          test.evaluation = new_evaluation.id;
          test.title = new_evaluation.title;
          await this.$store
            .dispatch(names.POST_NEW_TEST, test)
            .then((new_test) => {
              this.saveTestType({
                ...new_test,
                instrument_type: test.instrument_type,
              });
            });
        } else if (!test.delete_test) {
          await this.$store
            .dispatch(names.PATCH_NEW_TEST, {
              new_test_id: test.id,
              item: {
                evaluation_situation: test.evaluation_situation,
                weighing: test.weighing,
                examiner: test.examiner,
                work_type: test.work_type,
              },
            })
            .then((new_test) => {
              this.saveTestType({
                ...new_test,
                instrument_type: test.instrument_type,
              });
            });
        } else if (test.delete_test) {
          await this.$store.dispatch(names.DELETE_NEW_TEST, test.id);
        }
      }
    },
    saveTestType(new_test) {
      if (new_test.test_type_default == null) {
        this.$restful
          .Post("/evaluations2/test-type-default/", {
            test: new_test.id,
            evaluative_agent: new_test.instrument_type.evaluative_agent,
          })
          .then((response) => {
            this.$store.commit(
              "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
              response
            );
          });
      } else {
        this.$restful
          .Put(
            `/evaluations2/test-type-default/${new_test.test_type_default}/`,
            {
              test: new_test.id,
              evaluative_agent: new_test.instrument_type.evaluative_agent,
            }
          )
          .then((response) => {
            this.$store.commit(
              "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
              response
            );
          });
      }
    },
    deleteEvaluation(id, type) {
      const evaluation_type = this.epm_evaluation_types.find(
        (x) => x.id == type
      );
      let msg = "";
      if (evaluation_type) {
        if (type == 4) msg = "la " + evaluation_type.value.toLowerCase();
        else msg = "la evaluación " + evaluation_type.value.toLowerCase();
      }
      this.$swal({
        title: `¿Está seguro de que desea eliminar ${msg}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          const matter_evaluation = this.filterMatterEvaluations.find(
            (x) => x.id == id
          );
          this.$store.dispatch(names.DELETE_MATTER_EVALUATION, id).then(() => {
            if (matter_evaluation && matter_evaluation.evaluation != null)
              this.$store.dispatch(
                names.DELETE_NEW_EVALUATION,
                matter_evaluation.evaluation
              );
            this.$emit("fetch_mat_eva_stu_env_tim_all");
          });
        }
      });
    },
    patchEvaluationOrder(evaluation) {
      this.$store.dispatch(names.PATCH_MATTER_EVALUATION, {
        matter_evaluation_id: evaluation.id,
        item: {
          order: evaluation.order,
        },
      });
    },
    updatedEvaluation(evaluation, tests_list) {
      this.$store
        .dispatch(names.UPDATE_MATTER_EVALUATION, evaluation)
        .then((response) => {
          if (response.evaluation == null && response.type != 4) {
            this.createdNewEvaluation(response, tests_list);
          } else {
            if (response.evaluation != null) {
              this.$store
                .dispatch(names.PATCH_NEW_EVALUATION, {
                  new_evaluation_id: response.evaluation,
                  item: {
                    title: response.name,
                    description: response.description,
                    weighing: response.weighing,
                  },
                })
                .then((new_evaluation) => {
                  this.$store.commit(
                    names.MUTATE_NEW_EVALUATION,
                    new_evaluation
                  );
                  this.bulkCreateTest(tests_list, new_evaluation);
                });
            }
            this.$bvModal.hide(`edit-matter-evaluation-modal-${response.id}`);
          }
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.td-btn-wrapper {
  text-align: -webkit-center;
}
.evaluation-criteria-container {
  display: flex;
  margin-left: 0.25rem !important;
}
.description-evaluation {
  max-height: 200px;
  overflow-y: auto;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.evaluation-header {
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.evaluation-button-div {
  float: right;
  /* padding-right: 1rem; */
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.ec-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.ec-micro-badge {
  font-size: var(--thirth-font-size);
  text-align: center;
  width: fit-content !important;
  margin-right: 0.3em;
}
@media print {
  .ec-micro-badge {
    border: none;
  }
  .description-evaluation {
    overflow-y: none !important;
    max-height: 100% !important;
  }
}
</style>